.Business-Profile {
  .form-control {
    background-color: transparent;
    color: white;
    border-radius: 6px;
  }

  label {
    color: white;
  }
}

.dropdown-menu {
  background-color: rgb(0, 0, 0);
  background-clip: padding-box;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 0.25rem;
}

.logo-container {
  width: 50px;
  margin: 0 auto;
  margin-bottom: 55px;

  img {
    width: 100%;
  }
}

// .navbar {
//     background: none!important;
// }
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
  -webkit-transition-delay: 9999s;
}

.wrapper {
  height: auto !important;
}

.padding-left-4px {
  padding-left: 4px;
}

.padding-right-4px {
  padding-right: 4px;
}

.px-4px {
  padding-right: 4px !important;
  padding-left: 4px !important;
}

.form-control-lg {
  height: auto;
}

.businessTypes {
  .dropDownItem_iconMenu__1wCKU {
    max-height: 400px !important;
    overflow: auto;
  }

  .dropDownItem_iconMenu__1E92t {
    max-height: 400px !important;
    overflow: auto;
  }
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: rgba(221, 221, 221, 0.9);
  // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.9);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  // background: rgba(221,221,221,0.9);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.9);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(221, 221, 221, 0.9);
}

.light-gray {
  color: #d4d4d4;
}

.basic-font {
  font-size: 14.5px;
  padding-left: 10px;
}

.termsconditions {
  color: #9a9a9a !important;
  text-decoration: underline;

  a {
    color: #9a9a9a !important;
  }
}

.register-style {
  .react-select__value-container {
    padding-bottom: 0px !important;
    margin-top: 10px !important;
  }

  .form-check .form-check-sign::before {
    top: 5px;
    width: 16px;
    height: 16px;
  }

  .form-check .form-check-sign::after {
    font-size: 10px;
    left: -5px;
  }
}

.card-signup .form-check label {
  margin-left: 23px;
  padding-left: 35px;
}

.position-select-style .react-select__input>input {
  color: transparent !important;
  padding-top: 14px !important;

  &:focus {
    outline: none !important;
  }
}

.country-select-style {
  .react-select__input>input {
    padding-top: 10px !important;
    font-size: 12.5px !important;
  }

  .css-b8ldur-Input {
    margin-top: 0px;
    padding-top: 0px;
  }
}

.font-size-20 {
  font-size: 20px;
}

.cursor {
  cursor: pointer;
}

.sidebar:after,
.off-canvas-sidebar:after {
  background: #6610f2 !important;
}

.sidebar {
  .logo .simple-text {
    text-transform: capitalize;
  }

  .nav li>a {
    text-transform: capitalize;

    ul {
      display: none;
    }
  }
}

.font-size-14 {
  font-size: 14px;
}

.font-size-16 {
  font-size: 16px;
}

.form-check .form-check-label {
  font-size: 14px;
}

.bold {
  font-weight: bold;
}

.Italic-font {
  font-style: italic;
}

.close-date {
  .btn {
    padding: 5px 22px !important;
    margin-top: 3px;
    margin-left: 20px;
  }
}

.konvajs-content {
  // margin-left: auto;
  // margin-right: auto;
  // margin-top: 50px;
  // margin-bottom: 50px;
  width: 100%;

  canvas {
    padding-top: 10px;
    min-width: 1536px;
    // border: 1px solid black!important;
    // margin: auto!important;
  }
}

.react-grid-item.cssTransforms {
  background-color: white;
}

.react-grid-layout {
  background-color: lightgray;
  height: 2000px !important;
}

.MuiButtonBase-root {
  outline: none !important;
}

.setNumber {
  position: relative;
  width: 900px;
  height: 980px;
  border: 1px solid black;
}

.activeColor1 {
  background-color: lightgreen !important;
  color: white !important;
}

.activeColor2 {
  background-color: brown !important;
  color: white !important;
}

.activeColor3 {
  background-color: lightblue !important;
  color: white !important;
}

.activeColor4 {
  background-color: purple !important;
  color: white !important;
}

.activeColor5 {
  background-color: gold !important;
  color: white !important;
}

.activeColors {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
}

.options .card-body {
  min-height: auto !important;
}

.light-gray {
  color: lightgray;
}

.options .language-dropdown .btn {
  background-color: transparent !important;
}

.option-dropdown-button {
  font-size: 15px;
  padding: 10px;
}

.addSunbed .card-body {
  min-height: auto !important;
}

textarea.form-control {
  max-height: 200px !important;
}

.rule-button {
  background-color: #8600fd !important;
}

.create-element-style {
  width: 100%;
  // border: 1px solid black;
  height: 1000px;
  overflow: auto;
}

.form-control {
  padding: 10px !important;
  border-radius: 0px;
  border: 1px solid #e5e5e5 !important;
}

.input-group-prepend .input-group-text {
  border-radius: 0px !important;
}

.select-element {
  padding: 9px 12px;
  font-size: 18px;
  cursor: pointer;
}

.active-element {
  font-weight: bold;
  color: white !important;
  background-color: #46a8ff;
  border-radius: 20px;
}

.same-price-every {
  color: black !important;
  font-style: italic;
}

.reset-period {
  position: relative;
  top: -58px;
  width: 100%;
  margin-bottom: 0;
}

.reset-period2 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  color: white;
  padding: 2px 5px;
  margin-bottom: 0;
  font-weight: bold;
  align-self: center;
  background-color: red;
  animation: blinker 3s ease-in-out infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.custom-panel-header-style {
  height: auto !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.bank-alert {
  position: absolute;
  top: 45px;
  width: 100%;
  z-index: 99;
}

.top-calendar {
  // background-color: rgb(102, 0, 153);
  background-color: #7557f9;
  border-radius: 6px;
  padding: 0px 3px;

  p {
    color: white;
    padding: 5px;
    margin-bottom: 0px;
    font-size: 11px;
    font-weight: 600;
  }
}

.table-layout {
  position: relative;
}

.money-icon {
  color: #e91e63;
  font-size: 40px;
}

.card-facilities .btn-primary {
  background-color: transparent;
}

.card-facilities .btn-primary:focus {
  background-color: transparent;
}

.card-facilities .form-check .form-check-label {
  font-size: 12px;
}

.edit_height {
  min-height: 200px !important;
}

// New Css
h5.title {
  color: #7557f9;
}

.bold {
  color: #7557f9;
}

button.btn.btn-success {
  color: #fff;
  background-color: #7557f9;
  border-radius: 6px;
  // width: 18%;
}

button.btn-round.m-0.btn.btn-primary.disabled {
  background-color: #7557f9;
  border-color: #7557f9;
}

button.btn-round.btn.btn-secondary {
  background-color: #7557f9;
}

button.btn.btn-primary.rule-button.disabled {
  background-color: #7557f9;
  border-color: #7557f9;
}

button.btn-round.btn-block.bold.option-dropdown-button.dropdown-toggle.btn.btn-info {
  color: #fff !important;
  background-color: #7557f9;
}

.alert.alert-info {
  background-color: #7557f9 !important;
}

.admin-container {
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;

  &::before {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background-color: #000;
    opacity: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .d-flex {
    width: 100%;
  }

  .admin-form-container {
    margin-top: 3rem;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h4 {
      color: #7557f9;
      font-weight: bold;
    }

    form {
      width: 50%;

      select {
        &.form-control {
          text-align: center;
        }
      }

      p {
        text-transform: capitalize;
      }
    }

    .d-flex {
      width: 50%;
      margin: 2rem 0;
    }
  }
}

.plan-container {
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;

  &::before {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background-color: #000;
    opacity: 0.7;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  // .title-container {
  //   margin-bottom: 3rem;
  // }

  .plan-row {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  h5.title {
    color: #fff !important;
    text-align: center;
  }

  p.bold {
    text-align: center;
    color: #5ccf91;
    text-transform: capitalize;
  }

  .plan-two {
    margin-bottom: 1.3rem;
    margin-top: 1.4rem;
  }

  .mx-auto {
    display: flex;
    justify-content: center;
    align-self: center;
  }

  .coupon {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    margin-bottom: 2rem;

    .coupon-form {
      flex: 1.5;
    }

    .coupon-button {
      flex: 1;

      button {
        width: 100%;
      }
    }
  }

  .choose-button {
    &.plan-2 {
      button {
        background-color: #5ccf91;

        &.btn-outline-secondary {
          color: #6c757d !important;
          background: transparent;
          border-color: #5ccf91 !important;
        }
      }
    }

    button {
      background-color: #7557f9;
    }

    .btn-outline-secondary {
      color: #6c757d !important;
      background: transparent;
      border-color: #7557f9 !important;
    }
  }

  .plan-title {
    text-align: center;
  }

  .btn-round.outline {
    background-color: #fff !important;
  }

  .plan-1-prices {
    p {
      color: #000;
      font-weight: normal;

      .price-style {
        color: #7557f9;
        font-weight: bold;
      }
    }
  }

  .coupon-text {
    color: #000 !important;
    font-weight: normal;
  }

  hr.plan-2 {
    margin-top: 6rem;
  }

  .plan-2-prices {
    p {
      color: #000;
      font-weight: normal;

      .price-style {
        color: #5ccf91;
        font-weight: bold;
      }
    }
  }
}

// Employees Custom Style

.employees-container {
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;

  &::before {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    opacity: 0.7;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .row {
    width: 90%;

    @media screen and (max-width: 1199px) {
      width: 95% !important;
    }

    .employees-card {
      margin-top: 4rem;

      h5 {
        color: #7557f9;
        text-align: center;
        margin-top: 1rem;
      }

      .add-employee {
        background-color: #7557f9;
        font-weight: 600;
        border-radius: 6px;
        margin-bottom: 2rem;
        padding: 1rem 2rem;
      }

      .table-responsive {
        .table {

          th,
          td {
            color: gray;
          }

          .change {
            color: #5ccf91;
            margin-right: 1rem;
            cursor: pointer;
          }

          .remove {
            color: red;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.business-container {
  background-color: black;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  overflow-x: hidden;

  &::before {
    display: none;
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background-color: #000;
    opacity: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .row {
    width: 100%;

    .employees-card {
      margin-top: 4rem;

      h5 {
        color: #7557f9;
        text-align: center;
        margin-top: 1rem;
      }

      .add-employee {
        background-color: #7557f9;
        font-weight: 600;
        border-radius: 2rem;
        margin-bottom: 2rem;
        padding: 1rem 2rem;
      }

      .table-responsive {
        .table {

          th,
          td {
            color: gray;
          }

          .change {
            color: #5ccf91;
            margin-right: 1rem;
            cursor: pointer;
          }

          .remove {
            color: red;
            cursor: pointer;
          }
        }
      }
    }
  }
}

// Grid Planner Custom Styles

.grid-planner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;

  &::before {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background-color: #000;
    opacity: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .grid-planner-row {
    margin-top: 6rem;
    width: 98%;

    .grid-planner-inner-row {
      height: 100%;

      .card-user {
        padding: 2rem 0;

        &.first-card {
          padding-top: 3rem;
        }
      }
    }

    button.btn.btn-success {
      padding: 1rem 3rem;
    }

    .card-user {
      border-radius: 10px;

      .from-time,
      .to-time {
        color: gray;
        text-align: center;
        margin-top: 2rem;
      }

      &.second-card {
        margin-bottom: 0;
      }

      &.third-card {
        height: 100%;
        margin-bottom: 0;
      }

      .form-check {
        display: flex;
        justify-content: center;
      }

      .temporarily-closed {
        margin-top: 0.3rem;
      }

      h5 {
        text-align: center;
        color: #7557f9;
      }

      p.date-name {
        color: gray;
        font-weight: normal;
        margin-bottom: 0.5rem;
      }

      .rdt {
        input {
          width: 100%;
        }
      }

      .time-pickers {
        display: flex;
        justify-content: space-between;

        .col-md-3 {
          width: 90%;
        }
      }

      .btn-save {
        background-color: #7557f9;
        padding: 1rem 3rem;
      }
    }
  }
}

.menuuploads-container {
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;

  &::before {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background-color: #000;
    opacity: 0.7;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .row {
    width: 90%;

    .employees-card {
      margin-top: 4rem;

      h5 {
        color: #7557f9;
        text-align: center;
        margin-top: 1rem;
      }

      .add-employee {
        background-color: #7557f9;
        font-weight: 600;
        border-radius: 2rem;
        margin-bottom: 2rem;
        padding: 1rem 2rem;
      }

      .table-responsive {
        .table {

          th,
          td {
            color: gray;
          }

          .change {
            color: #5ccf91;
            margin-right: 1rem;
            cursor: pointer;
          }

          .remove {
            color: red;
            cursor: pointer;
          }
        }
      }
    }
  }
}

//Photo elements custom styles

.photo-elements-container {
  position: relative;
  margin-top: 0 !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  flex-direction: column;

  .elements-screen {
    margin-top: 2rem;

    .fileinput {
      .thumbnail {
        box-shadow: none;
      }
    }

    .image-preview-links {
      display: flex;
      justify-content: space-between;

      .change {
        color: #5ccf91;
        margin-right: 1rem;
        cursor: pointer;
      }

      .remove {
        color: red;
        cursor: pointer;
      }
    }
  }

  &::before {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background-color: #000;
    opacity: 0.7;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

// Options custom styles
.options-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 0 !important;
  padding: 2rem 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  .options-screen {
    margin-top: 5rem;
  }

  &::before {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    opacity: 0;
    background-repeat: repeat;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
  }

  .addSunbed {
    // height: 95%;

    .photo-required-radio {
      margin-right: 2rem;
    }
  }

  .addSunbed2 {
    height: 100%;

    .photo-required-radio {
      margin-right: 2rem;
    }
  }

  p.search-info {
    color: #5ccf91;
    text-align: center;
  }

  .google-search-bar {
    border-radius: 10px;

    div[class$="control"] {
      background-color: #f0e7fe;
      border: none;
    }

    div[class$="placeholder"],
    div[class$="indicatorContainer"] {
      color: #000000;
    }
  }

  .save-btn {
    background-color: #7557f9;
    padding: 0.8rem 3rem;
    border-radius: 40px;
  }

  .beach-photos {
    padding: 0 3rem;
  }

  .remove {
    color: red;
  }

  .change {
    color: #5ccf91;
  }

  .image-preview-links {
    display: flex;
    justify-content: space-between;
  }

  .text-info {
    color: #5ccf91 !important;
  }

  .options,
  .facilities-options {
    //height: 95%;
  }

  .options2,
  .facilities-options {
    height: 98%;
  }

  .guaranted-content {
    color: #5ccf91 !important;
  }

  .facilities-options {
    padding: 0 10rem;

    p {
      color: #5ccf91 !important;
    }
  }
}

.allign_items-center {
  align-items: center !important;
}

.has-time-error input {
  border-color: #e91e63;
}

.club-cards {
  margin-bottom: 25px;

  .addSunbed {
    height: 100%;
  }

  &.club-height {
    .half-sunbad {
      &>.addSunbed {
        height: 60%;
      }
    }
  }

  .half-sunbad {
    &>.addSunbed {
      min-height: 51%;
      height: auto;
    }
  }
}

@import "pages/grid-page";

.top-header-nav {
  @media (max-width: 991px) {
    background: #fff;

    a {
      color: #000 !important;
    }
  }
}

.sidebar {
  .nav {
    p {
      transform: rotate(0deg) !important;
    }
  }
}

.btn-facility {
  border-radius: 7px;
  margin: 0px;
  font-size: 14px;
  line-height: 1;
  padding: 10px;
  background-color: #6610f2;

  &:hover {
    box-shadow: none !important;
  }

  &.btn-outline-secondary {
    background-color: #ffffff;
    color: #9a9a9a;
    border-color: #a4a4a4;

    &:hover,
    &:focus {
      background-color: #ffffff;
    }
  }
}

.Select-placeholder,
.Select--single>.Select-control .Select-value {
  color: #ffffff;
}

.register-dropdown .react-select .Select-control {
  background: transparent !important;
}

.Select.has-value.Select--single>.Select-control .Select-value .Select-value-label,
.Select.has-value.is-pseudo-focused.Select--single>.Select-control .Select-value .Select-value-label {
  color: #333;
}

.searchBox::placeholder {
  color: #ffffff;
}

.btn-cusine {
  border-radius: 40px;
  margin: 3px;
  min-width: 175px;
  border: 1px solid;
  border-color: transparent;

  &.btn-outline-secondary {
    color: #6c757d;
    border-color: #6c757d;
  }

  @media (max-width: 1280px) {
    min-width: 150px;
    font-size: 10px;
  }
}

.custom-gutter-5 {
  .col {
    padding: 5px;
  }
}

.calender-right {
  .rdtPicker {
    &::after {
      right: 30px;
      left: auto;
    }

    &::before {
      right: 30px;
      left: auto;
    }
  }

  .rdt {
    .rdtPicker {
      right: 0;
    }
  }
}

.form-check-label {
  min-height: 27px;
}

.mobile-break-time {
  max-width: 100px;
  margin-top: 10px;
  display: block;

  @media (max-width: 1280px) {
    display: block;
  }
}

.day-name {
  @media (max-width: 1024px) {
    min-width: 75px !important;
  }
}

.break-time-col {
  @media (max-width: 1280px) {
    min-width: 100% !important;
  }
}

.hide-mobile-break-time {
  @media (max-width: 1280px) {
    display: none;
  }
}

.work-hours-row {
  @media (max-width: 1280px) {
    border-bottom: 1px solid #e1dcdc;
  }
}

.options-container .facilities-options,
.options-container .options {
  @media (max-width: 767px) {
    height: auto !important;
  }
}

// .mobile-check {
//   @media (max-width: 1280px) {
//     .form-check{
//       margin-top: 0;
//     }
//   }
// }

.t-textarea {
  border: 1px solid #878f95 !important;
  margin-top: 20px;
  border-radius: 20px !important;
}

.address-edit {
  margin-top: 25px;
  padding: 10px;
  background: #e7e7e7;
  font-size: 16px;
  text-align: center;

  img {
    margin-left: 20px;
    width: 25px;
    cursor: pointer;
  }
}

._loading_overlay_wrapper {
  width: 100%;
}

.color-box-block {
  display: block;
  cursor: pointer;
}

.color-box {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border: 2px solid #fff;
  cursor: pointer;
  margin: 5px;

  &.selected {
    background-color: rgb(255, 86, 48);
    border: 2px solid #000;
  }

  &.with-select {
    position: absolute;
    top: -2px;
    left: 14px;
    text-align: center;
  }
}

.reactstrap-confirm {
  .modal-footer {
    justify-content: flex-end;

    button {
      margin-right: 10px;
    }
  }
}

._3vt7_Mh4hRCFbp__dFqBCI {
  padding-right: 0px !important;
  border-radius: 0px !important;
}

.register-dropdown {
  flex-wrap: nowrap;

  .react-select {
    min-height: 35px;
    background: rgba(255, 255, 255, 0.1);
    width: 100%;
    border: none;

    .Select-control {
      background: rgba(255, 255, 255, 0.1);
      border: none;
      border-radius: 0px !important;
      color: #ffffff !important;
    }
  }

  .Select-arrow-zone {
    height: 39px;
  }

  .input-group-text {
    background: rgba(255, 255, 255, 0.1) !important;
    border-radius: 0px !important;
  }
}

.error-bank {
  .form-control {
    border-color: red;
  }
}

#PopoverFocus {
  cursor: pointer;
}

.popover-focus {
  cursor: pointer;
}

.plans-table {
  font-size: 12px;
  display: flex;
  color: #fff;

  .info-section {
    width: 170px;

    .header-col {
      min-height: 247px;
    }

    .td-col {
      min-width: 170px;
    }
  }

  .td-col {
    background: #fff;
    padding: 0px 5px;
    border: 1px solid #a7a1a1;
    color: #333;
    min-height: 75px;
    min-width: 165px;
    margin-right: -1px;
    margin-top: -1px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.statistics {
      min-height: 40px;
    }

    &.email-marketing {
      min-height: 50px;
    }

    &.per-res-cost {
      min-height: 50px;
    }

    &.reservation-count {
      min-height: 50px;
    }

    .form-check {
      padding-left: 10px;
      margin-top: 0;

      .form-check-label {
        min-height: 19px;
      }
    }

    .form-check-sign::before {
      border: 2px solid #bc81f6;
      border-radius: 100%;
      height: 20px;
      width: 20px;
    }

    .form-check-sign::after {
      color: #bc81f6;
      font-weight: bold;
      height: 20px;
      width: 20px;
      top: -3px;
    }

    .info-icon {
      width: 15px;
      margin-left: 10px;
      height: 15px;
    }

    .applied-coupon {
      color: #57a609;
      font-size: 16px;
      font-weight: bold;
    }

    .sms-toggle {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 5px;
    }
  }

  .plan-section {
    text-align: center;

    .header-col {
      background: #fff;
      padding: 10px;
      border: 1px solid #a7a1a1;
      margin-right: -1px;
      min-height: 247px;

      &.selected {
        background: #f0d0d3;

        .coupon-section {
          &:after {
            background: #f0d0d3;
          }
        }
      }

      .plan-name {
        font-size: 16px;
        font-weight: 500;
        color: #333;
        margin-top: 15px;
      }

      .btn-select {
        background: transparent;
        border: 2px solid #fa5865;
        padding: 5px 10px;
        border-radius: 5px;

        &.selected {
          background: #fa5865;
          color: #fff;
        }
      }

      .coupon-section {
        display: flex;
        position: relative;
        margin: 10px 0;
        width: 165px;

        input {
          border-radius: 0;
          border: 1px solid #9a9494;
          height: 38px;
        }

        &:after {
          content: "";
          display: inline-block;
          height: 100%;
          background: white;
          width: 10px;
          position: absolute;
          transform: skewX(-15deg);
          right: 49px;
          border-left: 1px solid #9a9494;
        }

        .btn-go {
          background: #fa5865;
          border: 0;
          border-radius: 0px;
          width: 70px;
          text-align: end;
          color: #fff;
          padding-right: 15px;
        }
      }

      .plan-price {
        color: #fa5865;
        margin-top: 10px;

        .lbl-total {
          color: #333;
        }
      }

      .applied-coupon {
        color: #333;
      }

      &.p-purple {
        .coupon-section {
          .btn-go {
            background: #bc81f6;
          }
        }

        .btn-select {
          border: 2px solid #bc81f6;

          &.selected {
            background: #bc81f6;
          }
        }

        .plan-price {
          color: #bc81f6;
        }

        &.selected {
          background: #e4d8f1;

          .coupon-section:after {
            background: #e4d8f1;
          }
        }
      }

      &.p-black {
        .coupon-section {
          .btn-go {
            background: #363640;
          }
        }

        .btn-select {
          border: 2px solid #363640;

          &.selected {
            background: #363640;
          }
        }

        .plan-price {
          color: #363640;
        }

        &.selected {
          background: #dcdada;

          .coupon-section:after {
            background: #dcdada;
          }
        }
      }

      &.p-green {
        .coupon-section {
          .btn-go {
            background: #57a609;
          }
        }

        .btn-select {
          border: 2px solid #57a609;

          &.selected {
            background: #57a609;
          }
        }

        .plan-price {
          color: #57a609;
        }

        &.selected {
          background: #cbd8be;

          .coupon-section:after {
            background: #cbd8be;
          }
        }
      }

      &.p-blue {
        .coupon-section {
          .btn-go {
            background: #660ff2;
          }
        }

        .btn-select {
          border: 2px solid #660ff2;

          &.selected {
            background: #660ff2;
          }
        }

        .plan-price {
          color: #660ff2;
        }

        &.selected {
          background: #e7d9fc;

          .coupon-section:after {
            background: #e7d9fc;
          }
        }
      }
    }
  }
}

.tbl-plan {
  background: #fff;

  &.table-bordered {
    th {
      border: 1px solid #dee2e6 !important;
    }
  }
}

.fixed-item {
  transition: all 0.3s;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding-top: 15px;
  position: relative;

  &.fixed,
  &.fullscreen-nav {
    position: fixed;
    top: 0;
    left: 0;
    background: #ffff;
    z-index: 1029;
    width: 100%;
    box-shadow: 0px 0px 13px #858383;
    padding-left: 100px;

    .font-size-16 {
      font-size: 13px;
    }

    .nav-link {
      font-size: 12px;
    }
  }

  .grid-page__header__item {
    margin-bottom: 0;
  }

  &.fullscreen-nav {
    z-index: 1032;
  }

  .btn-close {
    width: 30px;
    height: 30px;
    background: #dc3545;
    border: 1px solid #fff;
    border-radius: 100%;
    color: #fff;
    font-weight: bold;
    position: fixed;
    right: 6px;
    top: 3px;
  }

  .btn-fullscreen {
    height: 40px;
    width: 40px;
    background: transparent;
    border: 2px solid transparent;
    font-weight: bold;
    border-radius: 100%;
    color: #834cff;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 99;

    img {
      height: 20px;
      width: 20px;
    }
  }
}

.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1031;
  height: 100%;
  background: #fff;
  padding-left: 0px;
  margin-top: 90px;

  &.grid-1 {
    padding-top: 180px;
  }
}

.fullscreen-view {
  margin-top: 113px !important;
  height: calc(100vh - 113px) !important;
}

.fullscreen-view2 {
  margin-top: 118px !important;
  height: calc(100vh - 118px) !important;
}

.grid-page {
  .panel-header {
    height: auto;
    padding-top: 60px;
  }
}

.color-input {
  background: transparent;
  border: none;
  height: 35px;
  width: 100%;
}

.btn-file-input {
  overflow: hidden;
  max-width: 90px;

  &::-webkit-file-upload-button {
    visibility: hidden;
  }

  &::before {
    content: "Select logo";
    display: inline-block;
    background: #7557f9 !important;
    border-radius: 3px;
    padding: 5px 8px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    color: #fff;
    font-size: 13px;
  }
}

.bg-label {
  color: black !important;
  font-weight: 500;
}

.LogoPreview {
  margin-top: 15px;
  max-width: 100px;
}

.image-remove {
  position: absolute;
  top: 5px;
  right: -5px;
  background-color: red;
  padding: 4px 6px;
  border-radius: 50%;
  cursor: pointer;

  &::before {
    color: white;
    font-size: smaller;
  }
}

.error-tip {
  color: red;
  font-size: 12px;
  padding-top: 5px;
  font-weight: 500;
}

.bordered-qr {
  border: 5px solid black;
  padding: 5px;
  padding-top: 10px;
  border-radius: 20px;
}

.powertabo {
  font-size: 14px;
  font-weight: bolder;
  margin: 0.5rem 0;
  text-align: center;
}

.pdfView::-webkit-scrollbar {
  display: none;
}

.btn-remove-logo {
  height: 30px;
  margin-bottom: 0 !important;
}

.single-feild {
  @media (max-width: 991px) {
    min-width: 500px !important;
  }

  @media (max-width: 1200px) {
    min-width: 390px !important;
  }

  @media (max-width: 570px) {
    min-width: 250px !important;
  }
}

.single-permission {
  @media (max-width: 991px) {
    min-width: 241px !important;
  }
}

.zone-header {
  @media (max-width: 960px) {
    font-size: smaller;
    width: 30%;
    font-weight: 600;
  }
}

// .expanded-cols{
//   @media (min-width:960px){
//   font-size: smaller;
//   width: 30%;
//   font-weight: 600;
//   }
// }
@media (max-width: 900px) {
  .expanded-cols {
    flex: 0 0 100%;
    max-width: 100%;
    margin-top: 10px;
  }

  .day-name {
    min-width: 120px !important;
  }
}

.emplyoee-detail {
  padding: 12px 7px;
  vertical-align: middle;
  color: gray !important;
}

.emplyoee-head {
  color: gray !important;
  font-size: 1.45em;
  font-weight: 300;
  border: 0;
}

.change-super {
  color: #5ccf91 !important;
  margin-right: 1rem !important;
  cursor: pointer !important;
}

.border-row {
  border-bottom: 1px solid gainsboro;
}

.remove-super {
  color: red;
  cursor: pointer;
}

@media screen and (max-width: 40em) {
  .responsiveTable tbody tr {
    border: 1px solid gainsboro !important;
    padding: 0.25em;
    border-radius: 5px;
    margin-top: 5px;
  }
}

@media screen and (max-width: 575px) {
  .zone-section {
    width: 30% !important;
  }

  .zone-header {
    width: 30% !important;
  }
}

@media screen and (max-width: 500px) {
  .zone-section {
    width: 30% !important;
  }

  .zone-header {
    width: 30% !important;
  }
}

.employees-card {
  .responsiveTable {
    td.pivoted {
      @media screen and (max-width: 40em) {
        padding-left: calc(35% + 10px) !important;
      }
    }
  }
}

.uploaded-menu {
  position: relative;
  max-width: 300px !important;
  height: 300px;
}

.removed-shadow {
  box-shadow: none !important;
}

.save-light-button {
  background-color: #7557f9 !important;
  border-radius: 6px;
}

.save-light-button:hover {
  background-color: #9c85ff !important;
}

.save-light-button:active {
  background-color: #9c85ff !important;
}

@media screen and (max-width: 991px) {
  .top-header-nav .dropdown-width .nav-link {
    padding-top: 7px;
    padding-bottom: 7px;
    margin-top: 0px;
  }

  .navbar-toggler-bar {
    background: #8600fd;
  }

  .menuupload-row {
    margin-top: 35px !important;
  }

  .navbar {
    &.navbar-transparent {
      .navbar-wrapper {
        width: 100%;
        justify-content: space-between;
      }
    }
  }

  .flex-end-item {
    align-items: flex-start !important;
    justify-content: flex-start !important;
  }

  .flex-end-item .navbar-wrapper {
    margin-top: 2px;
  }

  .img-drop-width {
    max-width: 60px !important;
    margin-right: 20px;
  }

  .navbar-expand-lg {
    box-shadow: none !important;
  }

  .profile-logout {
    box-shadow: 0px 0px 5px gray !important;
    position: absolute !important;
    top: 45px !important;
    right: 18px !important;
  }

  .profile-logout svg {
    color: #000 !important;
    margin: 8px !important;
  }

  .dropdown-width {
    width: 170px !important;
    height: 33px !important;
    margin-top: 10px !important;
    margin: auto !important;
  }

  .dropdown-width a {
    border-radius: 25px !important;
  }

  .navbar .container,
  .navbar .container-fluid,
  .navbar .container-sm,
  .navbar .container-md,
  .navbar .container-lg,
  .navbar .container-xl {
    justify-content: space-between !important;
  }

  .dropdown.show .dropdown-menu,
  .dropdown .dropdown-menu {
    background-color: rgb(0, 0, 0) !important;
    background-clip: padding-box;
    border: 1px solid rgba(255, 255, 255, 0.15);
    border-radius: 0.25rem;
  }

  .profile-drop {
    background-color: white !important;
    list-style: none !important;
  }

  .top-header-nav {
    display: flex !important;
    margin-top: 0px !important;
    background-color: transparent !important;
    align-items: center;
  }

  .top-header-nav .nav-link {
    color: white !important;
  }

  .top-header-nav .dropdown-menu {
    margin-left: 0px !important;
    box-shadow: 0px 0px 5px gray !important;
  }

  .top-header-nav .navbar-nav {
    display: flex;
    flex-direction: row !important;
    background-color: transparent !important;
    color: white !important;
  }

  .three-dot {
    max-width: 30px !important;
    padding: 0px !important;
  }
}

.notification-title {
  font-size: 13px !important;
  font-weight: 600 !important;
  color: #000 !important;
  padding-bottom: 0;
}

.notification-description {
  font-size: 11px !important;
  font-weight: 400 !important;
  color: #000 !important;
  padding-top: 0;
}

.critical-dot {
  height: 10px;
  width: 10px;
  background-color: red;
  border-radius: 5px;
  position: absolute;
  top: 0;
  right: 0;
  animation: blinker 3s ease-in-out infinite;

  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
}

@media screen and (max-width: 480px) {
  .img-drop-width {
    margin-right: 0px;
  }
}

.profile-drop {
  max-width: fit-content !important;
  list-style-type: none !important;
}

.nav-bar-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.listof-business {
  position: absolute !important;
  will-change: transform !important;
}

.flex-end-item {
  justify-content: flex-end !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.dropdown-width .nav-link {
  border-radius: 7px !important;
  max-width: fit-content;
  margin-left: auto !important;
  margin-right: auto !important;
  font-size: 14px;
  color: #000000 !important;
  background-color: #ffffff !important;
}

.dropdown-toggle::after {
  margin-left: 0.7em !important;
}

.navbar .navbar-nav .nav-link {
  background-color: #000;
  padding: 9px;
  line-height: 1;
  border-radius: 3px;
  color: #ffffff !important;
  font-size: 14px !important;
  display: flex;
  align-items: center;
  margin-right: 0px;
  border: 1px solid #ffffff;

  &:hover,
  &:focus {
    background-color: #000000;
    opacity: 0.6;
  }

  @media only screen and (max-width: 999px) {
    background-color: transparent;
    color: #000000 !important;

    &:hover,
    &:focus {
      background-color: transparent !important;
      opacity: 0.6;
    }
  }
}

.navbar-transparent {
  .dropdown-toggle {
    &:after {
      border-top-color: #fff;
    }
  }
}

.navbar {
  box-shadow: none !important;
}

.navbar.navbar-absolute {
  padding-top: 16px;
  padding-bottom: 16px;
}

.nav-item-profile {
  background-color: #ffffff;
  border-radius: 7px;
  padding: 7px 9px;
}

.centered-nav .dropdown {
  list-style-type: none !important;
}

.centered-nav .dropdown-width .nav-link:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.d-flex-col {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 3px;
}

.el-size>span {
  display: flex;
  justify-content: space-around;
  font-weight: bold;
  font-size: 12px;
}

.fixed-nav-active {
  padding-top: 90px;
}

.btn-remove-price {
  position: absolute;
  right: -16px;
  top: -20px;
  color: red;
  font-size: 25px;
}

.price-item-nav {
  border: 4px solid transparent;
  transition: border 0.6s linear;
  z-index: 10;
  border-radius: 5px;

  &.active {
    border: 4px solid #987ffa !important;
  }

  .error-animated-box {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    height: 100%;
    margin: 0 5px;
    border-radius: 5px;
    pointer-events: none;
    border: 4px solid #FF0000;
    animation: blinker 3s ease-in-out infinite;

    &.active-err {
      border: 4px solid transparent;
    }
  }

  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
}

.auto-sunbad .addSunbed {
  height: auto !important;
}

///GENERIC CUSTOM STYLING

.btn-input-group {
  position: relative;
  align-items: center;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  overflow: hidden;

  .form-control {
    padding: 0;
    height: 40px;
    background-color: #f7f7f7;
    color: #6a6a6a;
    border: none !important;
    border-radius: 0;
    font-size: 14px;
  }

  .input-group-append {
    position: absolute;
    top: 4px;
    right: 4px;
    bottom: 4px;
    background-color: #ffffff;
    border-radius: 0px !important;
    overflow: hidden;
    z-index: 4;
  }

  .input-group-append.currency-type {
    right: 90px;
    background-color: transparent;
    align-items: center;
  }

  .btn {
    margin: 0;
    font-size: 14px;
    color: #1d1d1d;
    background-color: #ffffff;
    border-radius: 0px;
    padding: 7px 20px;
  }
}

.text-box {
  .form-control {
    background-color: #f7f7f7;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    font-size: 13px;
    color: #6a6a6a;
    line-height: 1.2;

    &:focus {
      background-color: #f7f7f7;
      border: 1px solid #e5e5e5;
      border-radius: 5px;
    }
  }
}

.grid-button {
  // background-color: #E0CFFC !important;
  // color: #1D1D1D !important;
  font-size: 14px;
  line-height: 1.2;
  border-radius: 6px;
  padding: 10px 18px;
}

.save-btn-secondary {
  background-color: #e0cffc !important;
  color: #1d1d1d !important;
  font-size: 14px;
  line-height: 1.2;
  border-radius: 6px;
  padding: 10px 18px;
}

.save-btn-blue {
  border-radius: 6px !important;
  font-weight: 600;
  margin: 0;

  &:disabled {
    background-color: #7557f9;
    border-color: #7557f9;
  }
}

.navbar.navbar-grey {
  background-color: #f0f0f0 !important;
  box-shadow: none;
  color: #000000;
}

.save-btn-primary {
  background-color: #f0e7fe !important;
  color: #000000 !important;
  margin-bottom: 0;
}

.text-input-label {
  font-size: 12px !important;
  color: #6a6a6a !important;
  margin-bottom: 4px !important;
  margin-left: 2px !important;
}

/////// CUSTOM OPTIONS PAGE STYLINGS

.page-heading {
  display: flex;
  align-items: center;
  gap: 14px;
  margin-bottom: 24px;

  i {
    font-size: 24px;
    color: #1e2f65;
    margin-bottom: 0;
  }

  h5 {
    font-size: 24px;
    color: #1e2f65;
    font-weight: bold;
    margin-bottom: 0;
  }
}

.option-title-main {
  font-size: 18px;
  line-height: 1.4;
  font-weight: 600;
  color: #1d1d1d;
  margin-bottom: 10px;
}

.option-title-sub {
  font-size: 13px;
  line-height: 1.4;
  font-weight: 500;
  color: #6a6a6a;
  margin-bottom: 8px;
}

.options-card {
  box-shadow: none;
  //border-bottom: 1px solid #A4A4A4;
  border-radius: 0;
  margin-bottom: 0;

  .language-dropdown {
    .btn {
      background-color: #ffffff;
      border: 1px solid #e5e5e5;
      border-radius: 6px;
      margin: 0;
      padding: 7px 10px;
      color: #000000;
      font-size: 14px;
      display: flex;
      align-items: center;
      width: 62px;

      &:focus {
        background-color: #ffffff;
      }
    }
  }
}

.option-label {
  font-size: 14px;
  color: #000000;
  line-height: 1;
  margin-bottom: 0;
}

.google-map-wrapper {
  position: relative;
}

.google-map-details {
  position: absolute;
  bottom: 21px;
  right: 62px;
}

.map-address-wrapper {
  background-color: #ffffff;
  width: 180px;
  padding: 10px;
  border-radius: 10px;

  img {
    width: 20px;
    height: 20px;
  }

  .map-address-title {
    font-size: 14px;
    font-weight: bold;
    color: #1d1d1d;
    margin-bottom: 4px;
  }

  .map-address-desc {
    font-size: 13px;
    line-height: 1.2;
    color: #6a6a6a;
    margin-bottom: 4px;
  }

  .map-address-input {
    textarea {
      border-radius: 10px !important;
      line-height: 1.2;
      text-align: left !important;
      margin-top: 0 !important;
    }
  }
}

.select-image {
  font-size: 12px;
  color: #6a6a6a;
  border: 1px dashed #6a6a6a;
  border-radius: 5px;
  padding: 5px 10px;
}

.upload-new {
  color: #6610f2;
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
}

.cuisine-image-wrapper {
  position: relative;
  border-radius: 9px;
  overflow: hidden;
  border: 1px solid #e5e5e5;
}

.image-preview-links {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  gap: 12px;
}

.change-image,
.remove-image {
  background-color: #ffffff;
  height: 26px;
  width: 26px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 12px;
    height: 12px;
  }
}

.cuisine-image {
  width: 140px;
  height: 140px;
  display: flex;
  align-items: center;

  img {
    height: 140px;
    width: 140px;
    object-fit: contain;
  }
}

.perfect-scrollbar-off .sidebar .sidebar-wrapper {
  overflow: hidden;
}

.perfect-scrollbar-off .main-panel {
  overflow: hidden;
}

.card-divider-line {
  border-bottom: 1px solid #a4a4a4;
}

.form-check-button .form-button.extra-bed {
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.time-input {
  .form-control {
    width: 54px;
    border-radius: 8px;
  }
}

///// BUSINESS PROFILE PAGE STYLINGS

.business-profile-card {
  box-shadow: none;
}

.business-profile-row {
  width: 98% !important;
}

.business-profile-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  flex-wrap: wrap;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  gap: 10px;
}

.btn-profile-autofill {
  border-radius: 6px;
  font-weight: 600;
}

.profile-title {
  font-size: 16px;
}

.title-working-hours {
  color: #1e2f65;
  font-size: 18px;
  margin-bottom: 0;
}

.open-close-select {
  padding: 10px 8px !important;

  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='grey' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 5px;
  border: 1px solid #dfdfdf;
  border-radius: 6px;
}

.working-time-date {
  border-radius: 6px !important;
  width: 57px;

  &:disabled {
    background-color: #e3e3e394 !important;
  }
}

.working-hours-header {
  display: flex;
  font-size: 14px;
  color: #1e2f65;
  font-weight: 600;
}

.working-day {
  flex: 0 0 62%;

  @media only screen and (min-width: 900px) and (max-width: 1120px) {
    flex: 0 0 60%;
  }

  @media only screen and (max-width: 491px) {
    flex: 0 0 68%;
  }
}

.work-break-time {
  flex: 0 0 30%;
}

.work-day-names {
  flex: 0 0 20%;

  @media only screen and (min-width: 900px) and (max-width: 1120px) {
    flex: 0 0 8%;
  }

  @media only screen and (max-width: 491px) {
    flex: 0 0 24%;
  }
}

.working-day-row {
  align-items: center;

  @media only screen and (max-width: 491px) {
    align-items: baseline;

    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 18px;
  }
}

.work-day-hours {
  flex: 0 0 40%;

  @media only screen and (max-width: 492px) {
    flex-wrap: wrap;
  }
}

.work-day-break {
  flex: 40%;

  @media only screen and (max-width: 492px) {
    flex-wrap: wrap;
  }

  .form-check {
    padding-left: 0;
  }
}

.work-day-short {
  font-size: 14px;
  color: #7557f9;
  font-weight: 600;

  @media only screen and (min-width: 900px) and (max-width: 1120px) {
    span {
      display: none;
    }
  }

  //   @media only screen and (max-width: 491px){
  //   span{
  //       display: none;
  //     }
  // }
}

.work-day-short[data-abbr] {
  @media only screen and (min-width: 900px) and (max-width: 1120px) {
    &::after {
      content: attr(data-abbr);
    }
  }

  // @media only screen and (max-width: 491px){
  //   &::after{
  //     content: attr(data-abbr);
  //   }
  // }
}

.day-toggler {
  @media only screen and (max-width: 491px) {
    width: 100%;
    padding-left: 29px;
  }
}

// ADMIN WIZARD PAGE STYLINGS
.admin-wizard-container-inner {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}

.admin-wizard-card {
  background-color: #ffffff;
  box-shadow: none;
  border-radius: 0;
}

.business-wizard-row {
  display: flex;
  column-gap: 18px;
  align-items: baseline;
  justify-content: center;

  @media only screen and (max-width: 560px) {
    flex-wrap: wrap;
    justify-content: left;
    padding-bottom: 10px;
    border-bottom: 1.5px solid #e5e5e5;
    margin-bottom: 10px;
  }
}

.business-value {
  width: 45%;

  @media only screen and (max-width: 560px) {
    width: 100%;
  }
}

.business-type {
  width: 30%;

  @media only screen and (max-width: 560px) {
    width: 50%;
  }
}

.business-controls {
  display: flex;
  align-items: baseline;
}

.btn-input-group.wizard-page {
  input {
    font-size: 12px;
    height: 38px;
  }
}

.btn-delete-business {
  padding: 4px;
  background-color: transparent;

  &:hover {
    background-color: transparent;
  }

  &:disabled {
    background-color: transparent;
  }
}

.nav-business-btn {
  background-color: #ffffff;
  color: #000000;
  font-size: 14px;
  border-radius: 6px;
  padding: 9px 16px;
  margin: 0;
  height: 35px;
  min-width: 85px;
  display: flex;
  align-items: center;

  &:hover {
    background-color: #ffffff;
    color: #000000;
    box-shadow: none;
  }

  &:active {
    background-color: #ffffff !important;
    color: #000000 !important;
    box-shadow: none !important;
  }

  &:focus {
    background-color: #ffffff !important;
    color: #000000 !important;
    box-shadow: none !important;
  }
}

.disabled-date-pick {
  input {
    color: #e3e3e3 !important;
  }
}

.invoice-page {
  @media print {
    padding: 1px;
    max-width: 290mm;
    margin: 0 auto;
  }

  .main-page {
    margin: 0mm auto;
    background: #fff;
  }

  .sub-page {
    padding: 1cm;
  }

  @page {
    size: A4;
    margin: 0;
  }

  @media print {
    .main-page {
      margin: 0;
      border: initial;
      border-radius: initial;
      width: initial;
      min-height: initial;
      box-shadow: initial;
      background: initial;
      page-break-after: always;
    }
  }
}

.react-table-select {
  .Select-value-label {
    color: #000 !important;
  }

  .Select-input {
    input {
      border: none;
    }
  }
}

.hide-clear .Select-clear-zone {
  display: none;
}

.history-box {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  p {
    margin-bottom: 0px;
  }

  .sub-history-box {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    .sub-history-box-left {
      display: flex;
      flex-direction: column;
      flex: 0.5;
    }

    .sub-history-box-right {
      flex: 0.5;
      display: flex;
      align-items: flex-end;
      flex-direction: column;
    }
  }
}

.modal-open #root {
  -webkit-filter: blur(4px);
  -moz-filter: blur(4px);
  -o-filter: blur(4px);
  -ms-filter: blur(4px);
  filter: blur(4px);
  filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='4');
}

/* For Mobile Device */
@media screen and (max-device-width: 480px) {
  .history-box {
    padding: 10px;
    margin-bottom: 15px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    p {
      margin-bottom: 0px;
    }

    .sub-history-box {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .sub-history-box-left {
        flex: 1;
        display: flex;
        flex-direction: column;
      }

      .sub-history-box-right {
        flex: 1;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
      }
    }
  }

  .options-container {
    margin-top: 25px !important;
  }

  .mobile-temporarily-closed div {
    padding-left: 0px !important;
  }

  .mobile-input {
    min-width: auto !important;
    max-width: inherit !important;
  }

  .mobile-card-divider-line {
    border: 0px !important;
  }
}

/* For Mobile Device Landscape */
@media (min-width: 480px) and (max-width: 920px) and (orientation: landscape) {
  .history-box {
    padding: 10px;
    margin-bottom: 15px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    p {
      margin-bottom: 0px;
    }

    .sub-history-box {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .sub-history-box-left {
        flex: 1;
        display: flex;
        flex-direction: column;
      }

      .sub-history-box-right {
        flex: 1;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
      }
    }
  }
}

/* For Tablet Device Portrait */
@media screen and (min-device-width: 768px) and (max-device-width: 1120px) and (orientation: portrait) {
  .history-box {
    padding: 10px;
    margin-bottom: 15px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    p {
      margin-bottom: 0px;
    }

    .sub-history-box {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .sub-history-box-left {
        flex: 1;
        display: flex;
        flex-direction: column;
      }

      .sub-history-box-right {
        flex: 1;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
      }
    }
  }
}

.plan-periods {
  .panel-header {
    padding: 0;
    height: 50px;
  }

  .reset-period {
    top: 70px;
  }
}

.price-select-dropdown {
  .Select-value {
    width: 90%;
  }
}

.slick-slider .slick-track {
  display: flex;
}

.slick-slider .slick-slide {
  float: none;
  height: auto;
}

.slick-slider .slick-slide>div {
  height: 100%;
}

.slick-slider .slick-dots li {
  width: auto;
  height: auto;
  margin: 0;
}

.slick-slider .slick-dots li.slick-active button:before {
  opacity: 1;
  color: #6844f9;
}

.slick-slider .slick-dots li button:before {
  font-size: 12px;
  color: #fff;
  opacity: 1;
}

.slick-slider .slick-prev:before,
.slick-slider .slick-next:before {
  font-size: 30px;
}

.stripe-form {
  max-width: 320px;
  border: 1px solid #cbcbcc;
  padding: 10px;
  box-shadow: 0px 0px 14px #cbcbcc;
  border-radius: 5px;
  margin-top: 10px;

  label {
    font-size: 12px;
    font-weight: bold;
    margin-top: 15px;
  }

  .StripeElement {
    border: 2px solid #cbcbcc;
    padding: 10px;
    border-radius: 5px;
  }

  .d-flex-child {
    width: 100%;

    &:first-child {
      margin-right: 5px;
    }
  }
}

.stripe-payment-button {
  padding: 10px;
  width: 100%;
  margin-top: 20px;
  background: #27ae60;
  color: #fff;
  font-weight: bold;
  border-radius: 5px;
  max-width: 320px;

  &:disabled {
    opacity: 0.6;
  }
}