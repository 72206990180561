.form-check {
  margin-top: 0.5rem;
}

.form-check .form-check-label {
  display: inline-block;
  position: relative;
  cursor: pointer;
  padding-left: 35px;
  line-height: 26px;
  margin-bottom: 0;
  -webkit-transition: color 0.3s linear;
  -moz-transition: color 0.3s linear;
  -o-transition: color 0.3s linear;
  -ms-transition: color 0.3s linear;
  transition: color 0.3s linear;
}

.form-check-button {
   padding-left: 0;
  .form-button {
    font-size: 14px;
    display: inline-block;
    position: relative;
    cursor: pointer;
    padding: 10px;
    line-height: 1;
    margin-bottom: 0;
    transition: color 0.3s linear;
    border: 1px solid #A4A4A4;
    border-radius: 7px;
    .form-check-sign{
      display: none;
    }
  }
  .form-check-label{
    padding-left: 0;
  }
  // .span.form-button {
  //   width: 100% !important;
  //   border-radius: 40px !important;
  //   border: 1px solid #e3e3e3 !important;
  //   left: 0 !important;
  //   top: 0 !important;
  //   padding: 1px;
  //   height: 100% !important;
  // }
  input[type="radio"] {
    opacity: 0;
  }
  input[type="radio"]:checked+.form-button{
    background: #7557f9 !important;
    color: #fff;
    border-color: #7557f9 !important;
  }  
}
.radio .form-check-sign {
  padding-left: 28px;
}

.form-check .form-check-sign::before,
.form-check .form-check-sign::after {
  content: " ";
  display: inline-block;
  position: absolute;
  width: 26px;
  height: 26px;
  left: 0;
  cursor: pointer;
  border-radius: 3px;
  top: 0;
  background-color: transparent;
  border: 1px solid $light-gray;
  -webkit-transition: opacity 0.3s linear;
  -moz-transition: opacity 0.3s linear;
  -o-transition: opacity 0.3s linear;
  -ms-transition: opacity 0.3s linear;
  transition: opacity 0.3s linear;
}

.form-check .form-check-sign::after {
  font-family: "Nucleo Outline";
  content: "\ea22";
  top: 0px;
  text-align: center;
  font-size: 14px;
  opacity: 0;
  color: $dark-background;
  border: 0;
  background-color: inherit;
}

.form-check.disabled .form-check-label,
.form-check.disabled .form-check-label {
  color: $dark-gray;
  opacity: 0.5;
  cursor: not-allowed;
}

.form-check input[type="checkbox"],
.radio input[type="radio"] {
  opacity: 0;
  position: absolute;
  visibility: hidden;
}
.form-check input[type="checkbox"]:checked + .form-check-sign::after {
  opacity: 1;
}

.form-control input[type="checkbox"]:disabled + .form-check-sign::before,
.checkbox input[type="checkbox"]:disabled + .form-check-sign::after {
  cursor: not-allowed;
}

.form-check input[type="checkbox"]:disabled + .form-check-sign,
.form-check input[type="radio"]:disabled + .form-check-sign {
  pointer-events: none;
}

.form-check-radio .form-check-sign::before,
.form-check-radio .form-check-sign::after {
  content: " ";
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid $light-gray;
  display: inline-block;
  position: absolute;
  left: 3px;
  top: 3px;
  padding: 1px;
  -webkit-transition: opacity 0.3s linear;
  -moz-transition: opacity 0.3s linear;
  -o-transition: opacity 0.3s linear;
  -ms-transition: opacity 0.3s linear;
  transition: opacity 0.3s linear;
}

.form-check-radio input[type="radio"] + .form-check-sign:after,
.form-check-radio input[type="radio"] {
  opacity: 0;
}
.form-check-radio input[type="radio"]:checked + .form-check-sign::after {
  width: 4px;
  height: 4px;
  background-color: $dark-background;
  border-color: $dark-background;
  top: 11px;
  left: 11px;
  opacity: 1;
}

.form-check-radio input[type="radio"]:checked + .form-check-sign::after {
  opacity: 1;
}

.form-check-radio input[type="radio"]:disabled + .form-check-sign {
  color: $dark-gray;
}

.form-check-radio input[type="radio"]:disabled + .form-check-sign::before,
.form-check-radio input[type="radio"]:disabled + .form-check-sign::after {
  color: $dark-gray;
}
