.react-datetime-input:disabled, .react-datetime-input{
    padding: 10px;
    background-color: transparent;
    border: 1px solid #e3e3e3;
    border-radius: 30px;
    color: #2c2c2c;
    line-height: normal;
    height: auto;
    font-size: 0.8571em;
    transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    box-shadow: none;
}
.react-datetime-input:focus{
    border: 1px solid #8600FD;
    box-shadow: none;
    outline: 0 !important;
    color: #2c2c2c;
}