$breakpoints-xs: 575.98px;
$breakpoints-sm: 767.98px;

.grid-page {
  &-container {
    .grid-page-pills {
      &:not(.flex-column) {
        .nav-item {
          margin-bottom: 20px;

          .nav-link {
            margin: 0 10px !important;

            &:not(.active) {
              background: transparent;
              border: solid 1px lightgreen;
            }
          }

          @media (max-width: $breakpoints-xs) {
            width: 100%;
            .nav-link {
              margin: 0 !important;
            }
          }
        }
      }
    }
  }

  &__header {
    &__item {
      border-right: solid 1px #dee2e6;
      margin-bottom: 20px;
    }
  }

  @media (max-width: $breakpoints-sm) {
    &__header {
      &__item {
        border-right: 0;

        &--cont {
          border-bottom: solid 1px #dee2e6;
        }
      }
    }
  }
}

@media (max-width: $breakpoints-sm) {
  .grid-planner-container .grid-planner-row {
      min-width: 100%;
      width: auto;

    .card-user.second-card {
      margin-bottom: 20px;
    }
    .form-check {
      padding-left: 0;
    }
  }
  .grid-planner-container .grid-planner-row button.btn.btn-success {
    width: 100%;
  }
}
.sunbed-element {
  img {
    height: 28px;
  } 
}