.dropdown-toggle::after{
    border-top-color: black;
    vertical-align: middle;
}
.navbar .navbar-nav .nav-link{
    background-color: #ffffff;
      padding: 9px;
      line-height: 1;
      border-radius: 3px;
      color: #ffffff !important;
      font-size: 14px !important;
      display: flex;
      align-items: center;
      margin-right: 15px;
      border: 1px solid #ffffff;
    &:hover, &:focus{
      background-color: #000000;
      opacity: 0.6;
    }
}
.dropdown-menu .nav-link {
    background-color: #ffffff; 
}
.admin .dropdown-menu:before {
    color: #ffffff;
}
