.countrySelect{
    background-color: rgba(222, 222, 222, 0.3);
    border-radius: 30px;
    border:none !important;
    outline: none;
    font-size: 0.8571em;
    min-height: 47px;
    min-width:88%;
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
}

.propertySelect{
    background-color: rgba(222, 222, 222, 0.1);
    border-radius: 30px;
    border:none !important;
    outline: none;
    font-size: 0.8571em;
    min-height: 47px;
    min-width: 93%;
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
}